<template>
  <div v-if="isLoggedIn" class="container-fluid">
    <table class="table" v-show="dataZA && dataZA.length > 0">
    <thead>
      <tr>
        <th>Zona</th>
        <th>Unidad</th>
        <th>Patente</th>
        <th>Despacho</th>
        <th>Citación</th>
        <th>Chofer</th>
        <th>Inicio</th>
        <th>Terminal</th>
        <th>Evento</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="dato in dataZA" :key="dato.despacho" :class="{ 'green-row': dato.color === 'V', 'red-row': dato.color === 'R', 'yellow-row': dato.color === 'A' }">
        <td>{{ dato.zona }}</td>
        <td>{{ dato.unidad }}</td>
        <td>{{ dato.patente }}</td>
        <td>{{ dato.despacho }}</td>
        <td>{{ dato.citacion }}</td>
        <td>{{ dato.chofer }}</td>
        <td>{{ dato.ini }}</td>
        <td>{{ dato.terminal }}</td>
        <td>{{ dato.evento }}</td>
      </tr>
    </tbody>
    </table>
    <br/>
    <br/>
    <table class="table" v-show="dataZ3 && dataZ3.length > 0">
    <thead>
      <tr>
        <th>Zona</th>
        <th>Unidad</th>
        <th>Patente</th>
        <th>Despacho</th>
        <th>Citación</th>
        <th>Chofer</th>
        <th>Inicio</th>
        <th>Terminal</th>
        <th>Evento</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="dato in dataZ3" :key="dato.despacho" :class="{ 'green-row': dato.color === 'V', 'red-row': dato.color === 'R', 'yellow-row': dato.color === 'A' }">
        <td>{{ dato.zona }}</td>
        <td>{{ dato.unidad }}</td>
        <td>{{ dato.patente }}</td>
        <td>{{ dato.despacho }}</td>
        <td>{{ dato.citacion }}</td>
        <td>{{ dato.chofer }}</td>
        <td>{{ dato.ini }}</td>
        <td>{{ dato.terminal }}</td>
        <td>{{ dato.evento }}</td>
      </tr>
    </tbody>
  </table>
  <br/>
    <br/>
    <table class="table" v-show="dataNA && dataNA.length > 0">
    <thead>
      <tr>
        <th>Zona</th>
        <th>Unidad</th>
        <th>Patente</th>
        <th>Despacho</th>
        <th>Citación</th>
        <th>Chofer</th>
        <th>Inicio</th>
        <th>Terminal</th>
        <th>Evento</th>
        <th>Ubicacion</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="dato in dataNA" :key="dato.despacho" :class="{ 'green-row': dato.color === 'V', 'red-row': dato.color === 'R', 'yellow-row': dato.color === 'A' }">
        <td>{{ dato.zona }}</td>
        <td>{{ dato.unidad }}</td>
        <td>{{ dato.patente }}</td>
        <td>{{ dato.despacho }}</td>
        <td>{{ dato.citacion }}</td>
        <td>{{ dato.chofer }}</td>
        <td>{{ dato.ini }}</td>
        <td>{{ dato.terminal }}</td>
        <td>{{ dato.evento }}</td>
        <td>
          <a :href="'https://www.google.com/maps?q=' + dato.gps" target="_blank">
            ver
          </a>
        </td>
      </tr>
    </tbody>
  </table>
  ultima actualizacion {{this.updated}}
  </div>
</template>
<style scoped>
.green-row td{
  color: rgb(94, 202, 94)!important;
}
.red-row td{
  color: rgb(236, 88, 88)!important;
}
.yellow-row td{
  color: rgb(243, 243, 113)!important;
}
img {
  display: relative;
  max-width: 90%;
  height: auto;
}
.el-button {
  font-size: 36px !important;
}
</style>
<script>
import { mapGetters } from 'vuex'
import config from '../../public/config.json'
import axios from 'axios'
// import back from '../components/back.vue'
import shared from '../shared/shared'
export default {
  extends: shared,
  name: 'Home',
  props: {
    tstori: String,
    nrocta: String,
    descripcion: String
  },
  computed: {
    ...mapGetters(['isLoggedIn']),
    ...mapGetters(['getToken']),
    dataZA () {
      return this.datos.filter(this.filterByZA)
    },
    dataZ3 () {
      return this.datos.filter(this.filterByZ3)
    },
    dataNA () {
      return this.datos.filter(this.filterByNA)
    }
  },
  components: {
  },
  mounted () {
    this.init()
    this.refresh()
    window.setInterval(() => {
      console.log('schedule#check ' + new Date())
      this.refresh()
    }, 1 * 60 * 1000)
  },
  data () {
    return {
      updated: '',
      datos: []
    }
  },
  methods: {
    filterByZA (item) {
      return item.zona === 'ZA' && (item.color === 'A' || item.color === 'R')
    },
    filterByZ3 (item) {
      return item.zona === 'Z3' && (item.color === 'A' || item.color === 'R')
    },
    filterByNA (item) {
      return item.zona === 'NA' && (item.color === 'A' || item.color === 'R')
    },
    strToDate (str) { // recibe string con formato dd/mm/yyyy
      var lret
      if (str) {
        var day = str.split('/')[0]
        var month = str.split('/')[1]
        var year = str.split('/')[2].substring(0, 4)
        var aelse = str.split('/')[2].substring(5, 100)
        lret = new Date(year + '-' + month + '-' + day + ' ' + aelse)
      } else {
        lret = new Date()
      }
      return lret
    },
    diff_minutes (dt2, dt1) {
      var diff = (dt2.getTime() - dt1.getTime()) / 1000
      diff /= 60
      return Math.abs(Math.round(diff))
    },
    refresh () {
      if (this.diff_minutes(this.strToDate(JSON.parse(localStorage.getItem('updated'))), new Date()) > 0.5 || JSON.parse(localStorage.getItem('updated')) === null) {
        console.log('schedule#run ' + new Date())
        this.init()
        this.updated = JSON.parse(localStorage.getItem('updated'))
      }
    },
    init () {
      var self = this
      // self.openFSL(self)
      // self.closeFSL(self)
      self.destino = self.$route.query.descripcion
      if (this.isLoggedIn) {
        const url = 'http://' + config.ip + '/comcam/choferesapp/monitor/corta/params;'
        axios.get(url, {
          dataType: 'json',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: self.getToken.token
          },
          mode: 'no-cors',
          credentials: 'include'
        })
          .then(function (response) {
            var today = new Date()
            self.updated = today.getDate() + '/' + (today.getMonth() + 1) + '/' + today.getFullYear() + ' ' + today.getHours() + ':' + ('00' + today.getMinutes()).substring(('00' + today.getMinutes()).length - 2)
            localStorage.setItem('updated', JSON.stringify(self.updated).split('T00:00:00Z[-03:00]').join(''))
            self.datos = JSON.parse(JSON.stringify(response.data).split('T00:00:00Z[-03:00]').join(''))
            // self.closeFSL(self)
          })
          .catch(function (error) {
            console.log(error)
            // self.closeFSL(self)
          })
      }
    },
    llegueterminal () {
      const self = this
      const url = 'http://' + config.ip + '/comcam/choferesapp/destinos/llegueterminal/params;tst=' + self.$route.query.tstori + ';tipoevento=terminal;inifin=ini;nrocta=""'
      // this.openFSL(this)
      fetch(url, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: this.getToken.token
        },
        body: ''
      })
        .then(async response => {
          if (response.status === 401) {
            this.errorMsg('permisos insuficientes para completar la operacion')
          } else {
            this.$router.go(-1)
          }
          // this.closeFSL(this)
        })
        .catch(error => {
          console.error('Error al grabar: ', error)
          this.errorMsg('Error al grabar')
          // this.closeFSL(this)
        })
    },
    iniciar () {
      this.$router.go(-1)
    },
    rechazo (producto) {
      this.successMsg('Rechazo de ' + producto + ' notificado a logistica')
    }
  }
}
</script>

<template>
<div>
<br/>
<!-- <h5>{{ this.dni }}</h5> -->
<br/>
<div v-for="(group, index) in groupedItems" :key="index">
<h3>Unidad {{ group.coduni }} {{ group.terminal }}  {{ group.hora }} hs</h3>
<ul>
    <li v-for="cliente in group.clientes" :key="cliente">{{ cliente }}</li>
</ul>
</div>
</div>
</template>
<script>
import axios from 'axios'
import config from '../../public/config.json'
export default {
  props: ['dni'],
  data () {
    return {
      items: [/* tu array de objetos aquí */]
    }
  },
  mounted () {
    this.GetCheckList()
  },
  computed: {
    groupedItems () {
      // Filtra los elementos que coinciden con el DNI
      const filteredItems = this.items.filter(item => item.dni === this.dni)
      // Agrupa los elementos por coduni, patente, hora, dni y terminal
      const groups = {}
      for (const item of filteredItems) {
        const key = `${item.coduni} ${item.patente} ${item.hora} ${item.dni} ${item.terminal}`
        if (!groups[key]) {
          groups[key] = {
            coduni: item.coduni,
            patente: item.patente,
            hora: item.hora,
            dni: item.dni,
            terminal: item.terminal,
            clientes: []
          }
        }
        groups[key].clientes.push(item.cliente)
      }
      // Convierte el objeto de grupos en un array
      return Object.values(groups)
    }
  },
  methods: {
    makeGetRequest (url, retryCount = 0) {
      const self = this
      return new Promise((resolve, reject) => {
        axios.get(url)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            if (retryCount < 3) {
              // Incrementar el contador de reintento
              const newRetryCount = retryCount + 1
              console.log(`Error en GET, reintentando (${newRetryCount})...`)
              // Esperar un breve período de tiempo antes de hacer el próximo intento
              setTimeout(() => {
                self.makeGetRequest(url, newRetryCount)
                  .then(response => {
                    resolve(response)
                  })
                  .catch(error => {
                    reject(error)
                  })
              }, 500) // Esperar 1 segundo antes de cada reintento
            } else {
              reject(error)
            }
          })
      })
    },
    GetCheckList () {
      var self = this
      var url = 'http://' + config.ip + '/logistica/getItinerario/'
      self.makeGetRequest(url)
        .then(response => {
          self.items = response
        })
        .catch(error => {
          console.log(error)
        })
    }
  }
}
</script>

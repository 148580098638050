<template>
  <div>
    <br/>
    <div class="row">
      <div class="col-md-5"></div>
      <div class="col-md-2">
        <datepicker placeholder="Select Date"></datepicker>
        <form @submit.prevent='login'>
          <el-input class= "mobile" v-model='username' placeholder='usuario' />
          <br />
          <br />
          <el-input class= "mobile" v-model='password' placeholder='clave' type='password' />
          <br />
          <br />
          <el-input class= "mobile" v-model='dni' placeholder='dni'/>
          <br />
          <br />
          <button type='submit' class='mobile btn btn-primary mb-2'>Ingresar</button>
        </form>
      </div>
      <div class="col-md-5"></div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'
import shared from '../shared/shared'
import axios from 'axios'
import config from '../../public/config.json'
export default {
  name: 'Login',
  props: {
    ticket: String
  },
  created () {
    if (this.$route.query.jwt) {
      const self = this
      this.setDni(this.$route.query.dni)
      const url = 'http://' + config.ip + '/login/auth'
      axios.get(url, {
        dataType: 'json',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: this.$route.query.jwt
        },
        mode: 'no-cors',
        credentials: 'include'
      })
        .then(function (response) {
          const data = JSON.parse(JSON.stringify(response.data).split('T00:00:00Z[-03:00]').join(''))
          if (data.user.includes('exception')) {
            console.log('error')
          } else {
            self.setToken(self.$route.query.jwt)
            self.setUser(data.user)
            self.setArea(data.area)
            self.$router.push('/Home')
          }
        })
        .catch(function (error) {
          console.log(error)
        })
      // si esta ok enruta al home
    }
    document.title = 'Ingresar a ChoferesApp'
  },
  extends: shared,
  data: () => {
    return {
      // loaded: false,
      username: '',
      password: '',
      dni: null
    }
  },
  mounted () {
  },
  beforeMount () {
  },
  computed: {
    ...mapGetters(['isLoggedIn']),
    ...mapGetters(['getArea']),
    ...mapGetters(['getDni'])
  },
  methods: {
    ...mapMutations(['setUser', 'setToken', 'setArea', 'setDni']),
    async login (e) {
      console.log(config.ip)
      e.preventDefault()
      fetch('http://' + config.ip + '/login/auth', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          usrNombre: this.username,
          usrClave: this.password
        })
      })
        .then(async response => {
          const { user, area, JWT } = await response.json()
          this.setUser(user)
          this.setArea(area)
          this.setToken(JWT)
          this.setDni(this.dni)
          // this.successMsg('Acceso Correcto, bienvenido ' + user)
          this.$router.push('/Home')
        })
        .catch(error => {
          this.setToken(null)
          this.setUser(null)
          this.setArea(null)
          this.setDni(null)
          console.error('Mensaje de error: ', error)
          this.errorMsg(error)
        })
    }
  }
}
</script>
<style>
/* body
{ */
    /* margin:auto;
    width:1024px;
    padding:10px;
    background-color:#4d3737;
    font-size:14px;
    font-family:Verdana; */
/* } */
.mobile{
  font-size:48px!important;
}
.el-input__inner{
  height: 60px!important;
}
</style>

import { createRouter, createWebHistory } from '@ionic/vue-router'
import Login from '../views/Login.vue'
import Home from '../views/Home.vue'
import CheckList from '../views/CheckList.vue'
import Destinos from '../views/Destinos.vue'
import Resumen from '../views/Resumen.vue'
import Detalle from '../views/Detalle.vue'
import Monitor from '../views/Monitor.vue'
import Itinerario from '../views/Itinerario.vue'

// Vue.use(VueRouter)

const routes = [
  {
    path: '/home',
    name: 'Home',
    component: Home
  },
  {
    path: '/',
    name: 'Login',
    component: Login
  },
  {
    path: '/CheckList',
    name: 'CheckList',
    component: CheckList
  },
  {
    path: '/Resumen',
    name: 'Resumen',
    component: Resumen
  },
  {
    path: '/Destinos',
    name: 'Destinos',
    component: Destinos
  },
  {
    path: '/Detalle',
    name: 'Detalle',
    component: Detalle
  },
  {
    path: '/Monitor',
    name: 'Monitor',
    component: Monitor
  },
  {
    path: '/Itinerario',
    name: 'Itinerario',
    component: Itinerario
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router

<template>
  <div class='row'>
    <!-- <back v-if="showBackButton"/> -->
    <ComponenteSOS v-if="showBackButton" :chofer="this.getDni" :unidad="''" />
  </div>
  <head>
    <title v-text="pageTitle"></title>
  </head>
  <body>
  <div class='row'>
  </div>
  <router-view/>
  </body>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'
import shared from './shared/shared'
import { ref } from 'vue'
import ComponenteSOS from './components/ComponenteSOS.vue'
export default {
  components: {
    ComponenteSOS
  },
  extends: shared,
  setup () {
    const activeIndex = ref('1')
    const handleSelect = (key, keyPath) => {
      console.log(key, keyPath)
    }
    return {
      activeIndex,
      handleSelect
    }
  },
  data () {
    return { pageTitle: 'Título por defecto', numero: 0 }
  },
  beforeMount () {
    document.title = 'App choferes'
  },
  computed: {
    ...mapGetters(['getArea']),
    ...mapGetters(['getDni']),
    showBackButton () {
      return this.$route.name !== 'Monitor'
    }
  },
  methods: {
    onKeyPress (event) {
      if (!this.isNumber(event)) return false
      if (event.keyCode === 13) { // Código de la tecla Enter
        this.$router.push('/?ticket=' + this.numero)
      }
    },
    xurl (param) {
      this.$router.push(param)
    },
    ...mapMutations(['setUser', 'setToken', 'setArea']),
    salir () {
      this.setUser(null)
      this.setArea(null)
      this.setToken(null)
      this.setDni(null)
      // this.successMsg('Sesion cerrada, gracias por utilizar el portal de tickets.')
      this.$router.push('/')
    },
    misRecurrentes () {
      this.$router.push('/Tickets/?precurrente=S')
    },
    misTickets () {
      this.$router.push('/Tickets/?pestado=A')
    },
    misTop5 () {
      this.$router.push('/Top5/?pprof=S')
    }
  }
}
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  /* background-color: #8d8c8c; */
  color: #bbbfc0;
}
#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
table {
  color: white;
}
th {
  /* background: white; */
  border-color: rgb(120,120,120);
  position: sticky;
  top: 0; /* Don't forget this, required for the stickiness */
  box-shadow: 0 2px 2px 2px rgba(0, 0, 0, 0.4);
  color: rgb(208, 208, 208);
}
td {
  border: 0px solid rgb(120, 120, 120);
  color: rgb(170, 170, 170);
  font-weight: bold !important;
}
tr.blue th {
  background: #2c3e50;
  border: 2px solid #2c3e50;
  color: rgb(192, 192, 192);
}
tr.gray td {
  background: gray;
  border: 2px solid gray;
  position: sticky;
  color: rgb(120, 120, 120);
}
.el-menu-item:hover{
  background-color: #222933 ! important;
  color: rgb(42, 154, 230) ! important;
}
.el-sub-menu__title:hover{
  background-color: #222933 ! important;
  color: rgb(42, 154, 230) ! important;
}
.el-sub-menu__title{
  background-color: #222933 ! important;
  color: rgb(190, 182, 176)! important;
}
tr:hover {
  border: 2px solid rgb(42, 154, 230);
}
</style>

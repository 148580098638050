<template>
  <div v-if="isLoggedIn" class="container-fluid">
    <br/>
    <!-- <back/> -->
    <br/>
    Destino: {{this.destino}}
    <el-button
                    v-if = "this.$route.query.nrocta==='--'"
                    size="large"
                    type="success"
                    icon="el-icon-arrow-right"
                    @click="llegueterminal()"
                    plain
                  >
                    Llegue a terminal
                  </el-button>
    <el-button
                    v-if = "this.$route.query.nrocta!='--'"
                    size="large"
                    type="success"
                    icon="el-icon-arrow-right"
                    @click="iniciar()"
                    plain
                  >
                    iniciar viaje
                  </el-button>
    <table class="table">
      <tbody>
        <tr v-for="producto in productos" :key="producto.id">
          <td>
            <div class="row">
              <div class="col-md-3">
                <strong></strong> <br> {{ producto.Producto }}
              </div>
              <div class="col-md-3">
                <strong></strong>  <br> {{ producto.CANTID }} lts
              </div>
            </div>
          </td>
          <td>
            <div class="row">
              <div class="col-md-12">
                  <el-button
                    size="large"
                    type="primary"
                    icon="el-icon-arrow-right"
                    @click="rechazo(producto.Producto)"
                    plain
                  >
                    rechazo
                  </el-button>
              </div>
            </div>
          </td>
        </tr>
        <el-divider></el-divider>
      </tbody>
    </table>
  </div>
</template>
<style scoped>
img {
  display: relative;
  max-width: 90%;
  height: auto;
}
.el-button {
  font-size: 36px !important;
}
</style>
<script>
import { mapGetters } from 'vuex'
import config from '../../public/config.json'
import axios from 'axios'
// import back from '../components/back.vue'
import shared from '../shared/shared'
export default {
  extends: shared,
  name: 'Home',
  props: {
    tstori: String,
    nrocta: String,
    descripcion: String
  },
  computed: {
    ...mapGetters(['isLoggedIn']),
    ...mapGetters(['getToken'])
  },
  components: {
  },
  mounted () {
    this.init()
  },
  data () {
    return {
      productos: [],
      destino: ''
    }
  },
  methods: {
    ini () {
      var self = this
      self.openFSL(self)
      self.closeFSL(self)
      self.destino = self.$route.query.descripcion
      if (this.isLoggedIn) {
        const url = 'http://' + config.ip + '/comcam/choferesapp/productos/params;tstori=' + self.$route.query.tstori + ';nrocta=' + self.$route.query.nrocta
        axios.get(url, {
          dataType: 'json',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: self.getToken.token
          },
          mode: 'no-cors',
          credentials: 'include'
        })
          .then(function (response) {
            self.productos = JSON.parse(JSON.stringify(response.data).split('T00:00:00Z[-03:00]').join(''))
            self.closeFSL(self)
          })
          .catch(function (error) {
            console.log(error)
            self.closeFSL(self)
          })
      }
    },
    llegueterminal () {
      const self = this
      const url = 'http://' + config.ip + '/comcam/choferesapp/destinos/llegueterminal/params;tst=' + self.$route.query.tstori + ';tipoevento=terminal;inifin=ini;nrocta=""'
      this.openFSL(this)
      fetch(url, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: this.getToken.token
        },
        body: ''
      })
        .then(async response => {
          if (response.status === 401) {
            this.errorMsg('permisos insuficientes para completar la operacion')
          } else {
            this.$router.go(-1)
          }
          this.closeFSL(this)
        })
        .catch(error => {
          console.error('Error al grabar: ', error)
          this.errorMsg('Error al grabar')
          this.closeFSL(this)
        })
    },
    iniciar () {
      this.$router.go(-1)
    },
    rechazo (producto) {
      this.successMsg('Rechazo de ' + producto + ' notificado a logistica')
    }
  }
}
</script>
